import React, {useState} from 'react';
import "./Registration.scss";
import loginImg from "../../img/LoginPageImg.png";
import LoginForm from "../../components/LoginForm/LoginForm";
import ConfirmEmail from "../../components/ConfirmEmail/ConfirmEmail";

function Registration() {
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);
	const [confirmEmail, setConfirmEmail] = useState(false);
	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};
	return (
		<div className="login">
			<div className="__wrapper">
				<div className="__image">
					<img src={loginImg} alt="img"/>
				</div>
				{
					confirmEmail ? <ConfirmEmail/> :
						<div className="__form">
							<LoginForm
								type="reg"
								isPrivacyAgreed={isPrivacyPolicyAgreed}
								setPrivacyPolicyValid={setIsPrivacyPolicyValid}
								setConfirmEmail={setConfirmEmail}
							/>
							<div className="form-agree">
								<div className="agree-text">
									<input
										type="checkbox"
										id="privacyPolicyAgree"
										checked={isPrivacyPolicyAgreed}
										onChange={handlePrivacyPolicyChange}
									/>
									<label htmlFor="privacyPolicyAgree">
										Вы соглашаетесь с
										<a href="/rules">Правилами пользования</a>
										<span>*</span>
									</label>
								</div>
								{!isPrivacyPolicyValid && (
									<p className="error-message">Подтвердите, что согласны с правилами пользования</p>
								)}
							</div>
						</div>
				}
			</div>
		</div>
	);
}

export default Registration;
import React, { useState } from 'react';
import "./ProfileAdmin.scss";
import AdminExchange from "../../components/AdminExchange/AdminExchange";
import AdminPassport from "../../components/AdminPassport/AdminPassport";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import AdminOrders from "../../components/AdminOrders/AdminOrders";

function ProfileAdmin() {
	const [selectedButton, setSelectedButton] = useState('exchange');
	const [selectedItem, setSelectedItem ] = useState("exchange");

	const renderComponent = () => {
		switch (selectedButton) {
			case 'exchange':
				return <AdminExchange/>;
			case 'orders':
				return <AdminOrders/>
			case 'passport':
				return <AdminPassport/>;
			default:
				return null;
		}
	};
	return (
		<div className="admin">
			<div className="_admin_wrapper">
				<div className="_admin_menu_container">
					<AdminMenu setSelectedButton={setSelectedButton} selectedItem={selectedItem}
					             setSelectedItem={setSelectedItem}/>
				</div>
				<div className="_admin_content_container">{renderComponent()}</div>
			</div>
		</div>
	);
}

export default ProfileAdmin;

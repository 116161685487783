import React, { useState, useEffect } from 'react';
import PassportItemModal from "../PassportItemModal/PassportItemModal";
import "./AdminPassport.scss";
import PersonalDataService from "../../services/PersonalDataService";
import toggleImg from "../../img/passportToggle.svg";

function AdminPassport() {
	const [passports, setPassports] = useState([]);
	const [isVerified, setIsVerified] = useState(false);
	const [selectedPassport, setSelectedPassport] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const fetchPassports = async () => {
			try {
				const response = await PersonalDataService.getPersonalData(isVerified);
				setPassports(response.data);
			} catch (error) {
				console.error('Error fetching passports:', error);
			}
		};

		fetchPassports();
	}, [isVerified]);

	const handleToggleVerified = () => {
		setIsVerified(!isVerified);
	};

	const handlePassportClick = (passport) => {
		setSelectedPassport(passport);
		setShowModal(true);
	};

	const handleApprove = async (userId) => {
		try {
			await PersonalDataService.approvePersonalData(userId);
			setShowModal(false);
			setPassports((prev) =>
				prev.map((p) => (p.user_id === userId ? { ...p, is_verified: true } : p))
			);
		} catch (error) {
			console.error('Error verifying passport:', error);
		}
	};

	const handleReject = async (userId) => {
		try {
			await PersonalDataService.rejectPersonalData(userId);
			setShowModal(false);
			setPassports((prev) => prev.filter((p) => p.user_id !== userId));
		} catch (error) {
			console.error('Error rejecting passport:', error);
		}
	};

	return (
		<div className="admin_passport">
			<h1>{isVerified ? 'Верифицированные' : 'Неверифицированные'}</h1>
			<button className="toggle_button" onClick={handleToggleVerified}>
				<img src={toggleImg} alt="img" />
			</button>
			<table className="passport_table">
				<thead>
				<tr>
					<th>ID</th>
					<th>Почта</th>
					<th>ФИО</th>
				</tr>
				</thead>
				<tbody>
				{passports.map((passport) => (
					<tr key={passport.user_id} onClick={() => handlePassportClick(passport)}>
						<td>{passport.user_id}</td>
						<td>{passport.email}</td>
						<td>{passport.full_name}</td>
					</tr>
				))}
				</tbody>
			</table>

			{showModal && selectedPassport && (
				<PassportItemModal
					passport={selectedPassport}
					onClose={() => setShowModal(false)}
					onApprove={() => handleApprove(selectedPassport.user_id)}
					onReject={() => handleReject(selectedPassport.user_id)}
				/>
			)}
		</div>
	);
}

export default AdminPassport;

import React from 'react';
import "./PartnerRequest.scss";
import {useNavigate} from "react-router-dom";

function PartnerRequest() {
	const navigate = useNavigate();

	const linkToPartners = () => {
		navigate("/partners");
		window.scrollTo(0,0);
	}

	return (
		<div className="request">
			<div className="container">
				<div className="__wrapper">
					<h3>Приглашаем компании к сотрудничеству</h3>
					<button onClick={linkToPartners}>
						Оставить заявку
					</button>
				</div>
			</div>
		</div>
	);
}

export default PartnerRequest;

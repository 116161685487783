import React from 'react';
import "./Privacy.scss";
import {useNavigate} from "react-router-dom";

function Privacy() {
	const navigate = useNavigate();
	const linkTo = () => {
		navigate("/rules");
		window.scrollTo(0, 0);
	}
	return (
		<div className="privacy">
			<div className="container-top">
				<div className="container">
					<h1>Политика конфиденциальности </h1>
					<p>Перед тем как воспользоваться услугами сервиса Dexpay, Пользователь обязан ознакомиться в полном объеме с Правилами и условиями предоставления услуг сервисом Dexpay. Использование услуг сервиса Dexpay возможно только в случае, если Пользователь принимает все условия соглашения.</p>
					<ul className="__list">
						<li>Общие положения</li>
							<ul className="__sublist">
								<li>1.1. Настоящая Политика конфиденциальности разработана в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ "О персональных данных" (далее - "Закон"), и определяет порядок сбора, использования, хранения и раскрытия персональной информации пользователей сервиса Dexpay (далее - "Сервис").</li>
								<li>1.2. Используемые в настоящей Политике термины имеют следующие значения:
									<ul className="__sublist">
										<li>Пользователь - физическое лицо, использующее Сервис Dexpay.</li>
										<li>Персональная информация - любая информация, относящаяся к определенному или
											определяемому на основании такой информации физическому лицу (субъекту
											персональных данных), включая, но не ограничиваясь, ФИО, адрес, номер
											телефона, адрес электронной почты.
										</li>
										<li>Обработка персональной информации - любое действие (операция) или
											совокупность действий (операций), совершаемых с использованием средств
											автоматизации или без использования таких средств с персональной
											информацией, включая сбор, запись, систематизацию, накопление, хранение,
											уточнение (обновление, изменение), извлечение, использование, передачу
											(распространение, предоставление, доступ), обезличивание, блокирование,
											удаление и уничтожение персональной информации.
										</li>
										<li>Оператор персональной информации - государственный орган, муниципальный
											орган, юридическое или физическое лицо, которому поручены операции с
											персональной информацией на основании договора с субъектом персональных
											данных или в соответствии с законодательством Российской Федерации.
										</li>
										<li>Сайт - интернет-ресурс, расположенный в доменной зоне dexpay.ru,
											предоставляющий доступ к Сервису Dexpay.
										</li>
										<li>Файлы cookie - небольшие текстовые файлы, отправляемые веб-сайтом на компьютер пользователя для хранения информации о его действиях на сайте. Файлы cookie позволяют веб-сайту запоминать предпочтения пользователя и анализировать его поведение, что может быть полезным для улучшения опыта использования сайта и предоставления персонализированных услуг.</li>
									</ul>
								</li>
							</ul>
						<li>Сбор и использование персональной информации</li>
						<ul className="__sublist">
							<li>2.1. Для регистрации в Сервисе Пользователь предоставляет следующие персональные данные: в фамилию, имя, а также отчество (если иное не вытекает из закона или национального обычая), гражданство, дату рождения, реквизиты документа, удостоверяющего личность, данные документов, подтверждающих право иностранного гражданина или лица без гражданства на пребывание (проживание) в Российской Федерации (если наличие таких документов обязательно в соответствии с международными договорами Российской Федерации и законодательством Российской Федерации), адрес места жительства (регистрации) или места пребывания, идентификационный номер налогоплательщика (при его наличии), электронная почта и пароль. Предоставление персональных данных осуществляется с согласия Пользователя на их обработку.</li>
							<li>2.2. Персональная информация Пользователя используется для предоставления доступа к
								функционалу Сервиса, выполнения соглашений и контрактов с Пользователем, обеспечения
								безопасности и защиты интересов Пользователя и Сервиса, а также для обеспечения
								соблюдения требований законодательства Российской Федерации.
							</li>
							<li>2.3. Сервис вправе использовать персональную информацию Пользователя для информирования
								его о новых продуктах и услугах, специальных предложениях и событиях, при условии
								получения согласия Пользователя на такие уведомления.
							</li>
							<li>2.4. Сервис имеет право при необходимости самостоятельно осуществлять деятельность по
								сбору дополнительных данных о Пользователе любыми доступными способами. Вся информация,
								собранная в результате такой деятельности не придается огласке, не передается третьим
								лицам, за исключением случаев, описанных в п. 2.5., 2.6. политики конфиденциальности и
								5.5. <span onClick={linkTo}>правил пользования</span>.
							</li>
							<li>2.5. Сервис имеет право передавать персональные данные Пользователя и детали,
								совершенных им операций при условии сохранения их конфиденциального статуса по
								официальному письменному запросу / решению суда / собственной инициативе (в случае
								возникновения потребности в проведении расследования) правоохранительным органам, а
								также Пользователю, которому они принадлежат.
							</li>
							<li>2.6. Сервис имеет право передавать детали операции и относящиеся к операции персональные
								данные Пользователя по официальному запросу Системы электронных расчетов для проведения
								внутренних расследований.
							</li>
							<li>2.7. При передаче персональной информации третьим лицам, Сервис обеспечивает соблюдение требований Закона и заключает с такими лицами соглашения о конфиденциальности и защите персональных данных.</li>
						</ul>
						<li>Использование файлов cookie</li>
						<ul className="__sublist">
							<li>3.1. Сервис может использовать файлы cookie для сбора и хранения информации о действиях Пользователя на сайте, а также для предоставления персонализированной рекламы и улучшения качества предоставляемых услуг.</li>
							<li>3.2. Пользователь вправе отключить использование файлов cookie в настройках своего браузера, однако это может повлиять на функциональность и доступность определенных возможностей Сервиса.</li>
						</ul>
						<li>Защита персональной информации</li>
						<ul className="__sublist">
							<li>4.1. Сбор, хранение и обработка персональной информации Пользователя осуществляются с применением средств защиты, обеспечивающих ее конфиденциальность, целостность и доступность.</li>
							<li>4.2. Сервис принимает необходимые организационные и технические меры для защиты персональной информации от неправомерного доступа, уничтожения, изменения, блокирования, копирования, распространения и других неправомерных действий третьих лиц.</li>
						</ul>
						<li>Права Пользователя</li>
						<ul className="__sublist">
							<li>5.1. Пользователь вправе в любое время получить информацию о своих персональных данных, внести изменения в них, а также отозвать согласие на их обработку, направив соответствующее письменное заявление в адрес Администрации Сервиса.</li>
							<li>5.2. Пользователь вправе обжаловать действия или бездействие Сервиса в отношении его персональных данных в соответствии с законодательством Российской Федерации.</li>
						</ul>
						<li>Заключительные положения</li>
						<ul className="__sublist">
							<li>6.1. Настоящая Политика конфиденциальности подлежит изменению или дополнению
								Администрацией Сервиса без предварительного уведомления Пользователя.
							</li>
							<li>6.2. Действующая версия Политики конфиденциальности всегда доступна на странице [ссылка
								на страницу с политикой конфиденциальности].
							</li>
							<li>6.3. Все споры и разногласия, возникающие в связи с исполнением настоящей Политики конфиденциальности, подлежат разрешению в соответствии с законодательством Российской Федерации.</li>
						</ul>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Privacy;
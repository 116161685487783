import React, { useState } from 'react';
import './CustomSelect.scss';

function CustomSelect({ options, value, onChange, error }) {
	const [isOpen, setIsOpen] = useState(false);
	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleSelect = (option) => {
		onChange(option);
		setIsOpen(false);
	};
	const selectedOption = options.find((opt) => opt.value === value.value);
	return (
		<div className="custom-select-wrapper">
			<div
				className={`custom-select ${isOpen ? 'open' : ''} ${error ? 'select-error' : ''}`}
				onClick={toggleDropdown}
			>
				{selectedOption ?
					<div className="custom-select__trigger">
						<span>
						{selectedOption
							? `${selectedOption.label}`
							: 'Выберите сеть'}
						</span>
						<span>≈ {selectedOption.price} USDT</span>
					</div> :
					<div className="custom-select__trigger">Выберите сеть</div>
				}
				<div className={`custom-options ${isOpen ? 'visible' : ''}`}>
					{options.map((option) => (
						<div
							className={`custom-option ${option.value === value ? 'selected' : ''}`}
							key={option.value}
							onClick={() => handleSelect(option)}
						>
							<span>{option.label}</span>
							<span> ≈ {option.price} USDT</span>
						</div>
					))}
				</div>
			</div>
			{error && <p className="error">{error}</p>}
		</div>
	);
}

export default CustomSelect;

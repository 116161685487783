import React, {useContext, useState} from 'react';
import "./PassportModal.scss";
import closeImg from "../../img/modalCloseImg.svg";
import PersonalDataService from "../../services/PersonalDataService";
import {Context} from "../../index";

function PassportModal({setModalActive, setIsPassportSent}) {
	const {store} = useContext(Context);
	const [step, setStep] = useState(1);
	const citizenshipOptions = ["Российская Федерация"];
	const documentOptions = ["Паспорт РФ"];

	const [errors, setErrors] = useState({});

	const [citizenship, setCitizenship] = useState("");
	const [documentId, setDocumentId] = useState("");

	const [fullName, setFullName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");

	const [passportSeries, setPassportSeries] = useState('');
	const [passportNumber, setPassportNumber] = useState('');
	const [placeOfBirth, setPlaceOfBirth] = useState('');
	const [issuedBy, setIssuedBy] = useState('');
	const [dateOfIssue, setDateOfIssue] = useState('');
	const [departmentCode, setDepartmentCode] = useState('');
	const [inn, setInn] = useState('');

	const [region, setRegion] = useState('');
	const [city, setCity] = useState('');
	const [street, setStreet] = useState('');
	const [house, setHouse] = useState('');
	const [apartment, setApartment] = useState('');

	const [isSameAddress, setIsSameAddress] = useState(false);
	const [actualRegion, setActualRegion] = useState('');
	const [actualCity, setActualCity] = useState('');
	const [actualStreet, setActualStreet] = useState('');
	const [actualHouse, setActualHouse] = useState('');
	const [actualApartment, setActualApartment] = useState('');

	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);
	const [isDataCorrect, setIsDataCorrect] = useState(false);


	const handleCitizenshipChange = (value) => {
		setCitizenship((prev) => {
			return prev === value ? '' : value;
		});
	};

	const handleDocumentChange = (value) => {
		setDocumentId((prev) => {
			return prev === value ? '' : value;
		});
	};

	const handleDateOfBirthChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		if (value.length > 2) value = value.slice(0, 2) + '.' + value.slice(2);
		if (value.length > 5) value = value.slice(0, 5) + '.' + value.slice(5);
		if (value.length > 10) value = value.slice(0, 10);
		setDateOfBirth(value);
	};

	const handlePassportSeriesChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		if (value.length > 4) {
			value = value.slice(0, 4);
		}
		setPassportSeries(value);
	};

	const handlePassportNumberChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		if (value.length > 6) {
			value = value.slice(0, 6);
		}
		setPassportNumber(value);
	};

	const handleDepartmentCodeChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		if (value.length > 6) {
			value = value.slice(0, 6);
		}
		if (value.length > 3) {
			value = `${value.slice(0, 3)}-${value.slice(3)}`;
		}
		setDepartmentCode(value);
	};

	const handleDateOfIssueChange = (e) => {
		let value = e.target.value.replace(/\D/g, '');
		if (value.length > 2) value = value.slice(0, 2) + '.' + value.slice(2);
		if (value.length > 5) value = value.slice(0, 5) + '.' + value.slice(5);
		if (value.length > 10) value = value.slice(0, 10);
		setDateOfIssue(value);
	};

	const validateStep1 = () => {
		const errors = {};
		if (!citizenship) errors.citizenship = 'Выберите гражданство';
		return errors;
	};
	const validateStep2 = () => {
		const errors = {};
		if (!documentId) errors.documentId = 'Выберите документ удостоверяющий личность';
		return errors;
	};
	const validateStep3 = () => {
		const errors = {};
		if (!fullName) errors.fullName = 'ФИО обязательно';
		if (!dateOfBirth) {
			errors.dateOfBirth = 'Дата рождения обязательно';
		} else if (!/^\d{2}\.\d{2}\.\d{4}$/.test(dateOfBirth)) {
			errors.dateOfBirth = 'Введите дату в формате ДД.ММ.ГГГГ';
		}
		return errors;
	};
	const validateStep4 = () => {
		const errors = {};
		if (!passportSeries) errors.passportSeries = 'Серия паспорта обязательно';
		if (!passportNumber) errors.passportNumber = 'Номер паспорта обязательно';
		if (!placeOfBirth) errors.placeOfBirth = 'Место рождения обязательно';
		if (!issuedBy) errors.issuedBy = 'Кем выдан обязательно';
		if (!dateOfIssue) {
			errors.dateOfIssue = 'Дата выдачи обязательно';
		} else if (!/^\d{2}\.\d{2}\.\d{4}$/.test(dateOfIssue)) {
			errors.dateOfIssue = 'Введите дату в формате ДД.ММ.ГГГГ';
		}
		if (!departmentCode) errors.departmentCode = 'Код подразделения обязательно';
		return errors;
	};
	const validateStep5 = () => {
		const errors = {};
		if (!region) errors.region = 'Область обязательна';
		if (!city) errors.city = 'Город обязателен';
		if (!street) errors.street = 'Улица обязательна';
		if (!house) errors.house = 'Дом обязателен';

		if(!isSameAddress){
			if (!actualRegion) errors.actualRegion = 'Область обязательна';
			if (!actualCity) errors.actualCity = 'Город обязателен';
			if (!actualStreet) errors.actualStreet = 'Улица обязательна';
			if (!actualHouse) errors.actualHouse = 'Дом обязателен';
		}
		return errors;
	};
	const validateStep6 = () => {
		const errors = {};
		if (!isPrivacyPolicyAgreed) {
			errors.privacyPolicy = 'Подтвердите, что согласны с политикой конфиденциальности';
		}

		if (!isDataCorrect) {
			errors.dataCorrect = 'Подтвердите, что указанные вами данные верны';
		}

		return errors;
	};

	const nextStep = () => {
		let errors = {};
		if (step === 1) {
			errors = validateStep1();
		} else if (step === 2) {
			errors = validateStep2();
		} else if (step === 3){
			errors = validateStep3();
		} else if (step === 4){
			errors = validateStep4();
		} else if (step === 5){
			errors = validateStep5();
		} else if (step === 6){
			errors = validateStep6();
		}

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else {
			setErrors({});
			setStep(step + 1);
		}
	};
	const prevStep = () => {
		setStep(step - 1);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const errors = {...validateStep1(), ...validateStep2(), ...validateStep3(), ...validateStep4(), ...validateStep5(), ...validateStep6()};
		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else {
			const data = {
				user_id: store.user.id,
				citizenship,
				documentId,
				fullName,
				dateOfBirth,
				passportSeries,
				passportNumber,
				placeOfBirth,
				issuedBy,
				dateOfIssue,
				departmentCode,
				inn,
				region,
				city,
				street,
				house,
				apartment,
				isSameAddress,
				actualRegion,
				actualCity,
				actualStreet,
				actualHouse,
				actualApartment
			}

			const response = await PersonalDataService.sendPersonalData(data);
			console.log(response)
			console.log("Sent")
			setModalActive(false)
			setIsPassportSent(true)
		}
	};


	return (
		<div className="modalBackdrop">
			<div className="modal">
				<button className="__close_modal" onClick={() => setModalActive(false)}><img src={closeImg} alt="img"/></button>
				<div className="__form">
					<ProgressTracker step={step}/>
					<form onSubmit={handleSubmit}>
						{step === 1 && (
							<div className="step step-1">
								<h2>Гражданство</h2>
								{citizenshipOptions.map((option) => (
									<label className="custom_checkbox" key={option}>
										<input
											type="radio"
											name="citizenship"
											value={option}
											checked={citizenship.includes(option)}
											onChange={() => handleCitizenshipChange(option)}
											className="custom_checkbox__value js-another-recipient"
										/>
										<span className="custom_checkbox__thumb">
					                            {citizenship.includes(option) && (
						                            <svg className="custom_checkbox__icon"
						                                 xmlns="http://www.w3.org/2000/svg"
						                                 viewBox="0 0 24 24">
							                            <path
								                            d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
						                            </svg>
					                            )}
											</span>
										<span className="custom_checkbox__text">{option}</span>

									</label>
								))}
								{errors.citizenship && <p className="error">{errors.citizenship}</p>}
								<div className="__nav_btn">
									<button type="button" onClick={nextStep}>Вперед</button>
								</div>
							</div>
						)}
						{step === 2 && (
							<div className="step step-2">
								<h2>Документ удостоверяющий личность</h2>
								{documentOptions.map((option) => (
									<label className="custom_checkbox" key={option}>
										<input
											type="radio"
											name="citizenship"
											value={option}
											checked={documentId.includes(option)}
											onChange={() => handleDocumentChange(option)}
											className="custom_checkbox__value js-another-recipient"
										/>
										<span className="custom_checkbox__thumb">
					                            {documentId.includes(option) && (
						                            <svg className="custom_checkbox__icon"
						                                 xmlns="http://www.w3.org/2000/svg"
						                                 viewBox="0 0 24 24">
							                            <path
								                            d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
						                            </svg>
					                            )}
											</span>
										<span className="custom_checkbox__text">{option}</span>

									</label>
								))}
								{errors.documentId && <p className="error">{errors.documentId}</p>}
								<div className="__nav_btn">
									<button type="button" onClick={prevStep}>Назад</button>
									<button type="button" onClick={nextStep}>Вперед</button>
								</div>
							</div>
						)}
						{step === 3 && (
							<div className="step step-3">
								<h2>Личные данные</h2>
								<div className="__input_wrapper">
									<label>ФИО:<span>*</span></label>
									<input
										className={`input ${errors.fullName ? 'input-error' : ''}`}
										type="text"
										value={fullName}
										onChange={(e) => setFullName(e.target.value)}
									/>
									{errors.fullName && <p className="error">{errors.fullName}</p>}
								</div>
								<div className="__input_wrapper">
									<label>Дата рождения:<span>*</span></label>
									<input
										className={`input ${errors.dateOfBirth ? 'input-error' : ''}`}
										type="text"
										value={dateOfBirth}
										onChange={handleDateOfBirthChange}
									/>
									{errors.dateOfBirth && <p className="error">{errors.dateOfBirth}</p>}

								</div>
								<div className="__nav_btn">
									<button type="button" onClick={prevStep}>Назад</button>
									<button type="button" onClick={nextStep}>Вперед</button>
								</div>
							</div>
						)}
						{step === 4 && (
							<div className="step step-4">
								<h2>Паспортные данные</h2>
								<div className="__input_wrapper">
									<label>Серия паспорта:<span>*</span></label>
									<input
										className={`input ${errors.passportSeries ? 'input-error' : ''}`}
										type="text"
										value={passportSeries}
										onChange={handlePassportSeriesChange}
									/>
									{errors.passportSeries && <p className="error">{errors.passportSeries}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Номер паспорта:<span>*</span></label>
									<input
										className={`input ${errors.passportNumber ? 'input-error' : ''}`}
										type="text"
										value={passportNumber}
										onChange={handlePassportNumberChange}
									/>
									{errors.passportNumber && <p className="error">{errors.passportNumber}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Место рождения:<span>*</span></label>
									<input
										className={`input ${errors.placeOfBirth ? 'input-error' : ''}`}
										type="text"
										value={placeOfBirth}
										onChange={(e) => setPlaceOfBirth(e.target.value)}
									/>
									{errors.placeOfBirth && <p className="error">{errors.placeOfBirth}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Кем выдан:<span>*</span></label>
									<input
										className={`input ${errors.issuedBy ? 'input-error' : ''}`}
										type="text"
										value={issuedBy}
										onChange={(e) => setIssuedBy(e.target.value)}
									/>
									{errors.issuedBy && <p className="error">{errors.issuedBy}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Дата выдачи:<span>*</span></label>
									<input
										className={`input ${errors.dateOfIssue ? 'input-error' : ''}`}
										type="text"
										value={dateOfIssue}
										onChange={handleDateOfIssueChange}
									/>
									{errors.dateOfIssue && <p className="error">{errors.dateOfIssue}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Код подразделения:<span>*</span></label>
									<input
										className={`input ${errors.departmentCode ? 'input-error' : ''}`}
										type="text"
										value={departmentCode}
										onChange={handleDepartmentCodeChange}
									/>
									{errors.departmentCode && <p className="error">{errors.departmentCode}</p>}
								</div>
								<div className="__input_wrapper">
									<label>ИНН (если есть):</label>
									<input
										className="input"
										type="text"
										value={inn}
										onChange={(e) => setInn(e.target.value)}
									/>
								</div>
								<div className="__nav_btn">
									<button type="button" onClick={prevStep}>Назад</button>
									<button type="button" onClick={nextStep}>Вперед</button>
								</div>
							</div>
						)}
						{step === 5 && (
							<div className="step step-5">
								<h2>Адрес регистрации</h2>
								<div className="__input_wrapper">
									<label>Область:<span>*</span></label>
									<input
										className={`input ${errors.region ? 'input-error' : ''}`}
										type="text"
										value={region}
										onChange={(e) => setRegion(e.target.value)}
									/>
									{errors.region && <p className="error">{errors.region}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Город:<span>*</span></label>
									<input
										className={`input ${errors.city ? 'input-error' : ''}`}
										type="text"
										value={city}
										onChange={(e) => setCity(e.target.value)}
									/>
									{errors.city && <p className="error">{errors.city}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Улица:<span>*</span></label>
									<input
										className={`input ${errors.street ? 'input-error' : ''}`}
										type="text"
										value={street}
										onChange={(e) => setStreet(e.target.value)}
									/>
									{errors.street && <p className="error">{errors.street}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Дом:<span>*</span></label>
									<input
										className={`input ${errors.house ? 'input-error' : ''}`}
										type="text"
										value={house}
										onChange={(e) => setHouse(e.target.value)}
									/>
									{errors.house && <p className="error">{errors.house}</p>}
								</div>

								<div className="__input_wrapper">
									<label>Квартира (если есть):</label>
									<input
										className='input'
										type="text"
										value={apartment}
										onChange={(e) => setApartment(e.target.value)}
									/>
								</div>


								<label className="custom_checkbox">
									<input
										type="checkbox"
										name="another_recipient"
										checked={isSameAddress}
										onChange={() => setIsSameAddress(!isSameAddress)}
										className="custom_checkbox__value js-another-recipient"
									/>
									<span className="custom_checkbox__thumb">
											{isSameAddress && (
												<svg className="custom_checkbox__icon"
												     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
													<path d="M9 16.2l-4.2-4.2 1.4-1.4L9 13.4l8.8-8.8 1.4 1.4L9 16.2z"/>
												</svg>
											)}
										</span>
									<span className="custom_checkbox__text">Адрес проживания совпадает с адресом регистрации</span>
								</label>

								{!isSameAddress && (
									<>
										<h2>Фактический адрес проживания</h2>
										<div className="__input_wrapper">
											<label>Область:<span>*</span></label>
											<input
												className={`input ${errors.actualRegion ? 'input-error' : ''}`}
												type="text"
												value={actualRegion}
												onChange={(e) => setActualRegion(e.target.value)}
											/>
											{errors.actualRegion && <p className="error">{errors.actualRegion}</p>}
										</div>

										<div className="__input_wrapper">
											<label>Город:<span>*</span></label>
											<input
												className={`input ${errors.actualCity ? 'input-error' : ''}`}
												type="text"
												value={actualCity}
												onChange={(e) => setActualCity(e.target.value)}
											/>
											{errors.actualCity && <p className="error">{errors.actualCity}</p>}
										</div>

										<div className="__input_wrapper">
											<label>Улица:<span>*</span></label>
											<input
												className={`input ${errors.actualStreet ? 'input-error' : ''}`}
												type="text"
												value={actualStreet}
												onChange={(e) => setActualStreet(e.target.value)}
											/>
											{errors.actualStreet && <p className="error">{errors.actualStreet}</p>}
										</div>

										<div className="__input_wrapper">
											<label>Дом:<span>*</span></label>
											<input
												className={`input ${errors.actualHouse ? 'input-error' : ''}`}
												type="text"
												value={actualHouse}
												onChange={(e) => setActualHouse(e.target.value)}
											/>
											{errors.actualHouse && <p className="error">{errors.actualHouse}</p>}
										</div>

										<div className="__input_wrapper">
											<label>Квартира (если есть):</label>
											<input
												className='input'
												type="text"
												value={actualApartment}
												onChange={(e) => setActualApartment(e.target.value)}
											/>
										</div>
									</>
								)}
								<div className="__nav_btn">
									<button type="button" onClick={prevStep}>Назад</button>
									<button type="button" onClick={nextStep}>Вперед</button>
								</div>
							</div>
						)}
						{step === 6 && (
							<div className="step step-6">
								<h2>Проверка и отправка данных</h2>
								<p className="__info">
									Пожалуйста, убедитесь, что все введенные вами данные верны. После отправки
									формы они будут обработаны в соответствии с политикой конфиденциальности!
								</p>
								<div className="form-agree">
									<div className="agree-text">
										<input
											type="checkbox"
											id="privacyPolicyAgree"
											checked={isPrivacyPolicyAgreed}
											onChange={(e) => setIsPrivacyPolicyAgreed(e.target.checked)}
										/>
										<label htmlFor="privacyPolicyAgree">
											Вы соглашаетесь с
											<a href="/privacy">Политикой конфиденцальности</a>
											<span>*</span>
										</label>
									</div>
									{errors.privacyPolicy && <p className="error-message">{errors.privacyPolicy}</p>}
								</div>
								<div className="form-agree">
									<div className="agree-text">
										<input
											type="checkbox"
											id="dataCorrect"
											checked={isDataCorrect}
											onChange={(e) => setIsDataCorrect(e.target.checked)}
										/>
										<label htmlFor="dataCorrect">
											Вы подтверждаете, что указанные вами данные верны
											<span>*</span>
										</label>
									</div>
									{errors.dataCorrect && <p className="error-message">{errors.dataCorrect}</p>}
								</div>
								<div className="__nav_btn">
									<button type="button" onClick={prevStep}>Назад</button>
									<button type="button" onClick={handleSubmit}>Отправить</button>
								</div>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}

const ProgressTracker = ({step}) => {
	return (
		<div className="progress-tracker">
			<div className={`tracker-item ${step === 1 ? 'active' : ''}`}>Гражданство</div>
			<div className={`tracker-item ${step === 2 ? 'active' : ''}`}>Документ</div>
			<div className={`tracker-item ${step === 3 ? 'active' : ''}`}>Личные данные</div>
			<div className={`tracker-item ${step === 4 ? 'active' : ''}`}>Паспортные данные</div>
			<div className={`tracker-item ${step === 5 ? 'active' : ''}`}>Адрес регистрации</div>
		</div>
	);
};

export default PassportModal;
import React from 'react';
import "./OrderInfo.scss"
import OrderInfoCard from "../OrderInfoCard/OrderInfoCard";
import arrow from "../../img/orderInfoArrow.svg";
import orderImg1 from "../../img/orderInfoCard1.svg";
import orderImg2 from "../../img/orderInfoCard2.svg";
import orderImg3 from "../../img/orderInfoCard3.svg";
import orderImg4 from "../../img/orderInfoCard4.svg";
import orderImg5 from "../../img/orderInfoCard5.svg";

function OrderInfo() {
	return (
		<div className="orderInfo">
			<h2>Как совершить обмен?</h2>
			<div className="orderInfo__cards">
				<OrderInfoCard
					imageUrl={orderImg1}
					text="Создайте кошелек для виртуальных активов если его нет"
				/>
				<img className="arrow-right" src={arrow} alt="img"/>
				<OrderInfoCard
					imageUrl={orderImg2}
					text="Пройдите простую процедуру регистрации"
				/>
				<img className="arrow-right" src={arrow} alt="img"/>
				<OrderInfoCard
					imageUrl={orderImg3}
					text="Укажите сумму обмена и реквизиты"
				/>
				<img className="arrow-right" src={arrow} alt="img"/>
				<OrderInfoCard
					imageUrl={orderImg4}
					text="Произведите оплату любым удобным способом"
				/>
				<img className="arrow-right" src={arrow} alt="img"/>
				<OrderInfoCard
					imageUrl={orderImg5}
					text="Начните пользоваться"
				/>
			</div>
		</div>
	);
}

export default OrderInfo;
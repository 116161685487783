import React, { useEffect } from 'react';
import './CopyNotification.scss';

const CopyNotification = ({ message, visible, onClose }) => {
	useEffect(() => {
		if (visible) {
			const timer = setTimeout(() => {
				onClose();
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [visible, onClose]);

	return (
		<div className={`notification ${visible ? 'show' : ''}`}>
			{message}
		</div>
	);
};

export default CopyNotification;

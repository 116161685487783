import $api from "../http";

export default class ExchangeService {
	static async getExchangeRate(userId = null) {
		const params = userId ? { params: { userId } } : {};
		return $api.get('/getExchangeRate', params);
	}

	static async getExchangeData(userId = null) {
		const params = userId ? { params: { userId } } : {};
		return $api.get('/getExchangeData', params);
	}

	static async toggleExchange(state) {
		return $api.post('/toggleExchange', { state });
	}

	static async setExchangeParams(customRate, percent) {
		return $api.post('/setExchangeParams', {customRate, percent });
	}

	static async setExchangeTime(startTime, endTime) {
		return $api.post('/setExchangeTime', {startTime, endTime });
	}
}

import React, { useState } from 'react';
import "./ReviewForm.scss";
import ReviewService from "../../services/ReviewService";

const ReviewForm = () => {
	const [name, setName] = useState('');
	const [text, setText] = useState('');
	const [error, setError] = useState(false);

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handleTextChange = (e) => {
		setText(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (name.trim() === '' || text.trim() === '') {
			setError(true);
			return;
		}

		ReviewService.addReview( { name, text })
			.then((result) => {
				if (result) {
					setName('');
					setText('');
					setError(false);
				} else {
					setError(true);
				}
			});
	};
	return (
		<div className="review-form">
			<div className="container-top">
				<form onSubmit={handleSubmit}>
					<h3>Оставьте свой отзыв</h3>
					<input type="text" value={name} onChange={handleNameChange} placeholder="Ваше имя" />
					<textarea value={text} onChange={handleTextChange} placeholder="Ваш отзыв" />
					<button type="submit">Отправить</button>
					{error && <p className="error">Заполните поля!</p>}
				</form>
			</div>
		</div>
	);
};

export default ReviewForm;
import $api from "../http";

export default class PersonalDataService {
	static async sendPersonalData(data){
		return $api.post('/addPersonalData', data)
	}

	static async checkValidation(id){
		return $api.post('/checkPassport', {id});
	}

	static async getPersonalData(isVerified) {
		return $api.get(`/getPersonalData`, {
			params: { verified: isVerified },
		});
	}

	static async approvePersonalData(userId){
		return $api.post("/approvePassport", {userId})
	}
	static async rejectPersonalData(userId){
		return $api.post("/rejectPassport", {userId})
	}
}
import React, {useState, useEffect, useContext} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import "./Navigation.scss";
import logo from "../../img/logo.svg";
import profileImg from "../../img/profileImg.svg";
import {Context} from "../../index";

function Navigation() {
	const navigate = useNavigate();
	const location = useLocation();
	const {store} = useContext(Context);
	const [menuOpen, setMenuOpen] = useState(false);
	const [sticky, setSticky] = useState(false);
	const [backgroundTransparent, setBackgroundTransparent] = useState(true);

	useEffect(() => {
		if (window.innerWidth > 767) {
			setMenuOpen(false);
		}

		const handleScroll = () => {
			if (window.scrollY > 100) {
				setSticky(true);
			} else {
				setSticky(false);
			}
		};
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		if (location.pathname === '/') {
			setBackgroundTransparent(false);
		} else {
			setBackgroundTransparent(true);
		}
	}, [location.pathname]);

	const handleItemClick = () => {
		window.scrollTo(0,0);
		setMenuOpen(false);
		document.body.style.overflow = '';
	}

	const menuToggleHandler = () => {
		setMenuOpen(!menuOpen);
		if (!menuOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
	};

	const signInClickHandler = () => {
		navigate("/login");
		setMenuOpen(false);
		window.scrollTo(0,0);
	};

	const profileClickHandler = () => {
		navigate("/profile");
		setMenuOpen(false);
		window.scrollTo(0,0);
	};

	function refreshPage(){
		navigate("/");
		window.location.reload();
		window.scrollTo(0,0);
	}

	return (
		<div className= {sticky || backgroundTransparent ? 'header header__sticky' : 'header'}>
			<div className="header__content">
				<div onClick= {refreshPage} className= "header__content__logo">
					<img src={logo} alt="img"/>
				</div>
				<nav className={`header__content__nav ${menuOpen && window.innerWidth < 767 ? "isMenu" : ""}`}>
				<ul>
					<li>
						<Link to="/about" onClick={handleItemClick}>
							О нас
						</Link>
					</li>
					<li>
						<Link to="/partners" onClick={handleItemClick}>
							Партнерам
						</Link>
					</li>
					<li>
						<Link to="/contacts" onClick={handleItemClick}>
							Контакты
						</Link>
					</li>
				</ul>
					{
						(!store.isAuth) ?
							<button onClick={signInClickHandler}>Войти</button> :
							<img onClick={profileClickHandler} src={profileImg} alt="img"/>
					}
				</nav>
				<div className={!menuOpen ? "header__content__toggle" : "header__content__toggle active"}
				     onClick={() => menuToggleHandler()}>
					<span></span>
				</div>
			</div>
		</div>
	);
}

export default observer(Navigation);
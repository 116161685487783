import React from 'react';
import "./Benefits.scss";
import benefitImg from "../../img/benefitsImg.png";
import benefitList1 from "../../img/benfitListImg1.svg";
import benefitList2 from "../../img/benefitListImg2.svg";
import benefitList3 from "../../img/benefitListImg3.svg";


function Benefits() {
	return (
		<div className="benefits">
			<div className="container">
				<div className="__wrapper">
					<img className="__image" src={benefitImg} alt="img"/>
					<div className="__info">
						<h2>Почему выбирают нас?</h2>
						<div className="__choose">
							<div className="__item">
								<img src={benefitList1} alt="img"/>
								<div className="__text">
									<h4>Безопасность:</h4>
									<p>Мы придаем высший приоритет защите данных и средств наших клиентов. Наш сервис предлагает передовые технологии безопасности, чтобы обеспечить защиту от любых угроз и рисков в мире виртуальных активов.</p>
								</div>
							</div>
							<div className="__item">
								<img src={benefitList2} alt="img"/>
								<div className="__text">
									<h4>Удобство использования:</h4>
									<p>DEXPAY создан с учетом удобства и интуитивной понятности. Наш простой и понятный интерфейс делает процесс покупки виртуальных валют быстрым и легким даже для новичков.</p>
								</div>
							</div>
							<div className="__item">
								<img src={benefitList3} alt="img"/>
								<div className="__text">
									<h4>Надежность и профессионализм:</h4>
									<p>Мы - команда экспертов в области виртуальных активов и блокчейна. С нашими знаниями и опытом мы гарантируем нашим клиентам надежность и профессиональный подход во всех аспектах нашего сервиса.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Benefits;

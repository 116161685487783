import React from 'react';
import "./ProfileSettings.scss";
import ChangePasswordForm from "../../components/ChangePasswordForm/ChangePasswordForm";
import ChangeEmailForm from "../../components/ChangeEmailForm/ChangeEmailForm";

function ProfileSettings(props) {
	return (
		<div className="__settings">
			<ChangeEmailForm/>
			<ChangePasswordForm/>
		</div>
	);
}

export default ProfileSettings;
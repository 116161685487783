import React from 'react';
import "./ContactUs.scss";
import telegram from "../../img/telegramContactUs.svg"

function ContactUs() {
	return (
		<div className="contactUs">
			<div className="container">
				<div className="__wrapper">
					<h3>Есть вопросы? Обращайтесь в нашу тех-поддержку:</h3>
					<div className="__image">
						<a href="https://t.me/dexpay_support_bot" target="_blank" rel="noopener">
							<img src={telegram} alt=""/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactUs;
import axios from "axios";
import {API_URL} from "../http";

export default class ReviewService {

	static async addReview({name, text}) {
		return await axios.post(`${API_URL}/addReview`, {name, text});
	}

	static async getReview() {
		const response =  await axios.get(`${API_URL}/getReview`);
		return { data: response.data };
	}
}
import $api from "../http";

export default class OrderService{
	static async getOrders(isClosed, startDate, endDate, userId, orderId) {
		return $api.get(`/getOrders`, {
			params: { closed: isClosed, startDate, endDate, userId, orderId },
		});
	}

	static async changeOrderStatus(orderId) {
		return $api.post(`/changeOrderStatus`, {orderId});
	}
}
import $api from "../http";
import {AxiosResponse} from 'axios';
import {AuthResponse} from "../models/AuthResponse";

export default class AuthService {
	static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
		return $api.post<AuthResponse>('/login', {email, password})
	}

	static async registration(email: string, password: string, ref?: string): Promise<AxiosResponse<AuthResponse>> {
		return $api.post<AuthResponse>('/registration', {email, password, ref})
	}

	static async logout(): Promise<void> {
		return $api.post('/logout')
	}

	static async forgetPassword(email: string): Promise<AxiosResponse<AuthResponse>>{
		return $api.post<AuthResponse>('/reset', {email})
	}

	static async changeEmail(email: string, id: string): Promise<AxiosResponse<AuthResponse>>{
		return $api.post<AuthResponse>('/changeEmail', {email, id})
	}
	static async changePassword(password: string, activationLink?: string, id?: string): Promise<AxiosResponse<AuthResponse>>{
		return $api.post<AuthResponse>('/changePassword', {password, activationLink, id})
	}

	static async confirmEmail(email: string): Promise<AxiosResponse<AuthResponse>> {
		return $api.post<AuthResponse>('/confirmEmail', {email})
	}

}
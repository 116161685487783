import React from 'react';
import './PassportItemModal.scss';
import closeImg from "../../img/modalCloseImg.svg";

function PassportItemModal({ passport, onClose, onApprove, onReject }) {
	if (!passport) return null;

	return (
		<div className="modal-backdrop">
			<div className="modal">
				<button className="close-button" onClick={onClose}>
					<img src={closeImg} alt="img"/>
				</button>
				<h2>Паспортные данные</h2>
				<table className="modal-table">
					<tbody>
						<tr>
							<td>User ID:</td>
							<td>{passport.user_id}</td>
						</tr>
						<tr>
							<td>Email:</td>
							<td>{passport.email}</td>
						</tr>
						<tr>
							<td>Гражданство:</td>
							<td>{passport.citizenship}</td>
						</tr>
						<tr>
							<td>Документ:</td>
							<td>{passport.document_id}</td>
						</tr>
						<tr>
							<td>Полное имя:</td>
							<td>{passport.full_name}</td>
						</tr>
						<tr>
							<td>Дата рождения:</td>
							<td>{passport.date_of_birth}</td>
						</tr>
						<tr>
							<td>Серия паспорта:</td>
							<td>{passport.passport_series}</td>
						</tr>
						<tr>
							<td>Номер паспорта:</td>
							<td>{passport.passport_number}</td>
						</tr>
						<tr>
							<td>Выдан:</td>
							<td>{passport.issued_by}</td>
						</tr>
						<tr>
							<td>Дата выдачи:</td>
							<td>{passport.date_of_issue}</td>
						</tr>
						<tr>
							<td>Код подразделения:</td>
							<td>{passport.department_code}</td>
						</tr>
						<tr>
							<td>ИНН:</td>
							<td>{passport.inn ? passport.inn : "-"}</td>
						</tr>
						<tr>
							<td>Регион:</td>
							<td>{passport.region}</td>
						</tr>
						<tr>
							<td>Город:</td>
							<td>{passport.city}</td>
						</tr>
						<tr>
							<td>Улица:</td>
							<td>{passport.street}</td>
						</tr>
						<tr>
							<td>Дом:</td>
							<td>{passport.house}</td>
						</tr>
						<tr>
							<td>Квартира:</td>
							<td>{passport.apartment ? passport.apartment : "-"}</td>
						</tr>
						{!passport.is_same_address && (
							<>
								<tr>
									<td>Актаульный регион:</td>
									<td>{passport.actual_region}</td>
								</tr>
								<tr>
									<td>Актаульный город:</td>
									<td>{passport.actual_city}</td>
								</tr>
								<tr>
									<td>Актаульная улица:</td>
									<td>{passport.actual_street}</td>
								</tr>
								<tr>
									<td>Актаульный дом:</td>
									<td>{passport.actual_house}</td>
								</tr>
								<tr>
									<td>Актаульная квартира:</td>
									<td>{passport.actual_apartment}</td>
								</tr>
							</>
						)}
					</tbody>
				</table>
				{!passport.is_verified && (
					<div className="actions">
						<button onClick={() => onReject(passport.user_id)}>Отклонить</button>
						<button onClick={() => onApprove(passport.user_id)}>Одобрить</button>
					</div>
				)}
			</div>
		</div>
	);
}

export default PassportItemModal;

import React from 'react';
import "./Legality.scss";

function Legality() {

	const LegalityCard = ({title, text}) => {
		return (
			<div className="__card">
				<h3>{title}</h3>
				<p>{text}</p>
			</div>
		);
	}
	return (
		<div className="legality">
			<div className="container">
				<h2>Законодательство в РФ активно развивается в сторону либерализации цифровых валют</h2>
				<div className="__wrapper">
					<LegalityCard
						title="Июль 2020"
						text="Вступил в силу закон о Цифровых Финансовых Активах и Цифровой валюте 259-ФЗ"
					/>
					<LegalityCard
						title="Август 2020"
						text="Криптовалюта получила статус Цифровой валюты и признана имуществом - 115-ФЗ"
					/>
					<LegalityCard
						title="Июль 2022"
						text="Приняты поправки в Налоговый Кодекс РФ об отмене НДС на операции с ЦФА - 324-ФЗ"
					/>
					<LegalityCard
						title="Сентябрь 2022"
						text="ЦБ РФ и Минфин согласовали использование криптовалюты в трансграничных расчетах"
					/>
				</div>
			</div>
		</div>
	);
}

export default Legality;
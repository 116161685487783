import React from 'react';
import "./Header.scss";
import Exchanger from "../Exchanger/Exchanger";

function Header() {
	return (
		<div className="main">
			<div className="container">
				<div className="container-top">
					<div className="__content">
						<div className="__text_wrapper">
							<h1>Покупка USDT - это просто!</h1>
							<p>Присоединяйтесь к миллионам пользователей, которые уже начали свой путь в мире виртуальных активов. Наш сервис предлагает быстрый и безопасный способ приобретения виртуальных активов без лишних сложностей. Просто введите желаемую сумму для покупки и адрес вашего кошелька, а остальное мы возьмем на себя. Наша система обеспечивает быструю и надежную транзакцию, а все данные передаются по защищенным каналам, обеспечивая вашу безопасность и конфиденциальность.</p>

						</div>
						<div className="__exchanger_wrapper">
							<Exchanger/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;

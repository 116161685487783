import React, { useState, useEffect, useRef } from 'react';
import './TruncatedText.scss';

function TruncatedText({ text, maxLength = 10 }) {
	const [isHovered, setIsHovered] = useState(false);
	const truncatedTextRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (truncatedTextRef.current && !truncatedTextRef.current.contains(event.target)) {
				setIsHovered(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const getTruncatedText = (text) => {
		return text.length > maxLength
			? `${text.slice(0, maxLength / 2)}...${text.slice(-maxLength / 2)}`
			: text;
	};

	return (
		<span
			ref={truncatedTextRef}
			className="truncated-text"
			onClick={() => setIsHovered(true)}
		>
			{getTruncatedText(text)}
			<div className={isHovered ? "bubble_content active" : "bubble_content"}>
				{text}
			</div>
		</span>
	);
}

export default TruncatedText;

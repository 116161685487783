import React from 'react';
import './CookieConsent.scss';
import {useNavigate} from "react-router-dom";

const CookieConsent = ({onAccept}) => {
	const navigate = useNavigate();

	const handleAccept = () => {
		onAccept();
	};

	const linkToPrivacy = () =>{
		navigate("/privacy");
		window.scrollTo(0, 0);
	}

	return (
		<div className="cookie-consent-form">
			<div className="consent-message">
				<p>Файлы cookie позволяют веб-сайту запоминать предпочтения пользователя и анализировать его поведение, что может быть полезным для улучшения опыта использования сайта и предоставления персонализированных услуг. Мы используем файлы <span onClick={linkToPrivacy}>cookie</span> для улучшения вашего опыта.</p>
				<div className="btns">
					<button onClick={handleAccept}>Принять</button>
					<button onClick={handleAccept}>Отклонить</button>
				</div>
			</div>
		</div>
	);
};

export default CookieConsent;
import React,{useState} from 'react';
import "./FAQ.scss";
import arrowUp from "../../img/arrowUpImg.svg";
import arrowUpWhite from "../../img/arrowUpImgWhite.svg";

function Faq({color}) {
	const faqData = [
		{
			question: "Какой виртальный актив можно приобрести на нашей платформе? ",
			answer: "Вы можете приобрести только USDT на нашей платформе. USDT (Tether) - это стабильная монета, привязанная к доллару США в соотношении 1:1. Это означает, что за каждый выпущенный USDT в обороте имеется соответствующий доллар. USDT является одним из самых надежных токенов в мире виртуальных активов благодаря своей стабильности и прозрачности. "
		},
		{
			question: "Безопасна ли покупка виртуального актива на Dexpay?",
			answer: "Покупка виртуального актива на Dexpay абсолютно безопасна. Мы обеспечиваем высокий уровень защиты благодаря использованию только банковского эквайринга без участия третьих лиц. Это означает, что ваши финансовые данные и средства остаются полностью защищенными во время транзакций. Мы придерживаемся строгих стандартов безопасности, чтобы обеспечить надежность и конфиденциальность каждой операции нашего сервиса."
		},
		{
			question: "Как долго ждать поступления виртуального актива на кошелек?",
			answer: "Время поступления виртуального актива на ваш кошелек зависит от загруженности сети блокчейна. В среднем, это занимает около 15 минут, однако может быть как более быстрым, так и замедленным в зависимости от текущей активности сети."
		},
		{
			question: "Что делать если возникла проблема при использовании сервисом и нужна помощь?",
			answer: "Если у вас возникла проблема при использовании нашего сервиса и вам нужна помощь, вы можете связаться с нами по указанным контактам или обратиться в нашу техническую поддержку через Telegram."
		}];
	const [expandedItem, setExpandedItem] = useState(null);

	const toggleItem = (index) => {
		setExpandedItem(expandedItem === index ? null : index);
	};

	return (
		<div className="faq">
			<div className="container">
				<h2>Часто задаваемые вопросы</h2>
				<ul>
					{faqData.map((item, i) => (
						<li key={i}>
							<button className="__question" onClick={() => toggleItem(i)}>
								<h5>{item.question}</h5>
								{
									color === "white" ?
										<img className={`__arrow_image ${expandedItem === i ? 'expanded' : ''}`}
										     src={arrowUpWhite} alt="img"/> :
										<img className={`__arrow_image ${expandedItem === i ? 'expanded' : ''}`}
										     src={arrowUp} alt="img"/>
								}

							</button>
							<div className={`__answer_hide ${expandedItem === i ? 'expanded' : ''}`}>
								<div className="__body">
									<div className="__item">
										<p>{item.answer}</p>
									</div>
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Faq;

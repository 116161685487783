import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import "./MapPoint.scss";

function MapPoint() {
	return (
		<div className="map_point">
			<YMaps width="100%"
			       height="100%"
			>
				<Map
					defaultState={{
						center: [55.814942, 49.180732],
						zoom: 15,
						controls: ["fullscreenControl"],
					}}
					modules={[ "control.FullscreenControl"]}
					width="100%"
					height="100%"
				>
					<Placemark defaultGeometry={[55.814942, 49.180732]} />
				</Map>
			</YMaps>
		</div>
	);
}

export default MapPoint;
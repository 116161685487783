import React from 'react';
import './ProfileSupport.scss';
import Faq from "../../components/FAQ/FAQ";
import supportMail from "../../img/supportMail.svg";
import telegram from "../../img/footerTelegram.svg";
import linkedIn from "../../img/footerLnkdIn.svg";
import vk from "../../img/footerVk.svg";

function ProfileSupport() {
	return (
		<div className="support">
			<div className="__container">
				<div className="__layout">
					<div className="__wrapper">
						<img alt="img" className="__main_image" src={supportMail}/>
						<h2>Нужна помощь? Напишите нам!</h2>
						<div className="__contacts_wrapper">
							<div className="__contacts_item">
								<h5>Почта</h5>
								<a href="mailto:info@dexpay.ru">info@dexpay.ru</a>
							</div>
							<div className="__contacts_item">
								<h5>Номер телефона</h5>
								<a href="tel:+79673671612">+7(967)367-16-12</a>
							</div>
							<div className="__contacts_item">
								<h5>Телеграм бот</h5>
								<a href="https://t.me/dexpay_support_bot" target="_blank" rel="noopener">@dexpay_support_bot</a>
							</div>
							<div className="__contacts_item">
								<h5>Соцсети</h5>
								<div className="networks">
									<a href="https://t.me/dexpay_support_bot" target="_blank" rel="noopener"><img src={telegram} alt="img"/></a>
									{/*<a href="#" target="_blank" rel="noopener"><img src={linkedIn} alt="img"/></a>*/}
									{/*<a href="#" target="_blank" rel="noopener"><img src={vk} alt="img"/></a>*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Faq color={"white"}/>
		</div>
	);
}

export default ProfileSupport;

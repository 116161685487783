import React, {useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./ResetPassword.scss";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

function ResetPassword() {
	const navigate = useNavigate();

	const urlParams = new URLSearchParams(window.location.search);
	const linkParam = urlParams.get('activationLink');


	useEffect(() => {
		if (!linkParam) {
			navigate('/');
		}
	}, [navigate]);

	return (
		<div className="__reset_password">
			<div className="container-top">
				<div className="container">
					<ChangePasswordForm activationLink={linkParam}/>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
import React, {useContext} from 'react';
import "./ConfirmEmail.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";



function ConfirmEmail(props) {
	const {store} = useContext(Context);
	const navigate = useNavigate();


	const navigateToProfile = () => {
		navigate("/profile");
		window.scrollTo(0, 0)
	}

	return (
		<div className="__confirm_email">
			<h2>Подтвердите почту</h2>
			<p>Мы отправили письмо вам на почту. Перейдите по ссылке чтобы активировать аккаунт. Если вы уже перешли по ссылке из письма - обновите страницу.</p>
			<span>{store.user.email}</span>
			<button onClick={navigateToProfile}>Подтвердить позже</button>
		</div>
	);
}

export default observer(ConfirmEmail);
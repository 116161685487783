import React, {useContext, useEffect, useState} from 'react';
import "./ProfileTransactions.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import emptyImg from "../../img/transactionEmptyImg.svg";
import {useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import Spinner from "../../components/Spinner/Spinner";
import TruncatedText from "../../components/TruncedText/TruncedText";
import CopyNotification from "../../components/CopyNotification/CopyNotification";

function ProfileTransactions() {
	const {store} = useContext(Context);
	const [notification, setNotification] = useState({ visible: false, message: '' });
	const [transaction, setTransaction] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();


	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				setNotification({ visible: true, message: 'Текст скопирован в буфер обмена' });
			})
			.catch((err) => {
				console.error('Не удалось скопировать текст: ', err);
			});
	};

	const handleNotificationClose = () => {
		setNotification({ visible: false, message: '' });
	};


	useEffect(() => {
		async function fetchData() {
			try {
				const response = await UserService.fetchTransactions(store.user.id);
				setTransaction(response.data);
				setLoading(false);

			} catch (e) {
				console.log(e);
				setLoading(false);
			}
		}

		fetchData().then(r => r);
	}, []);

	const statusStyles = {
		pending: {
			color: '#808080',
			text: 'В процессе'
		},
		completed: {
			color: '#00FF07FF',
			text: 'Успешно'
		},
		paid: {
			color: '#ffffff',
			text: 'Завершено'
		},
		failed: {
			color: '#FF0000FF',
			text: 'Неуспешно'
		}
	};

	const formatDate = (utcDateString) => {
		const date = new Date(utcDateString);
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		};
		return date.toLocaleString(undefined, options);
	};



	const linkToMain = () => {
		navigate("/");
		window.scrollTo(0, 0);
	}
	return (
		<div className="transactions">
			{
				loading ?
					<Spinner/>
					:
					transaction.length > 0 ?
						<div className="wrapper">
							<div className="table-wrapper">
								<table className="transaction-table">
									<thead>
									<tr>
										<th>ID Транзакции</th>
										<th>Отправлено</th>
										<th>Получено</th>
										<th>Курс</th>
										<th>Кошелек</th>
										<th>Сеть</th>
										<th>Memo</th>
										<th>Примечание</th>
										<th>Дата</th>
										<th>Статус</th>
									</tr>
									</thead>
									<tbody>
									{transaction.map((transaction, i) => (
										<tr key={i}>
											<td>
												<TruncatedText text={transaction.orderId}/>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
												     cursor="pointer"
												     className="__copy"
												     onClick={() => copyToClipboard(transaction.orderId)}>
													<path fillRule="evenodd" clipRule="evenodd"
													      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z"
													      fill="currentColor"></path>
												</svg>
											</td>
											<td>{transaction.sumIn}&nbsp;₽</td>
											<td>{transaction.sumOut}&nbsp;USDT</td>
											<td>{transaction.rate}</td>
											<td>
												<TruncatedText text={transaction.wallet}/>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
												     cursor="pointer"
												     className="__copy"
												     onClick={() => copyToClipboard(transaction.wallet)}>
													<path fillRule="evenodd" clipRule="evenodd"
													      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z"
													      fill="currentColor"></path>
												</svg>
											</td>
											<td>{transaction.network}</td>
											<td>{transaction.userMemo ? transaction.userMemo : "-"}</td>
											<td>{transaction.note ? transaction.note : "-"}</td>
											<td>{formatDate(transaction.time)}</td>
											<td style={{color: statusStyles[transaction.status]?.color}}>
												{statusStyles[transaction.status]?.text || ""}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
						:
						<div className="__empty">
						<div className="__image">
								<img src={emptyImg} alt="img"/>
							</div>
							<h2>На данный момент у вас нет завершённых обменов или транзакций</h2>
							<button onClick={linkToMain}>Приступить</button>
						</div>
			}
			<CopyNotification message={notification.message} visible={notification.visible}
			                  onClose={handleNotificationClose}/>
		</div>
	);
}

export default observer(ProfileTransactions);
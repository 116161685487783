import React, {useContext, useState} from 'react';
import "./ForgetPassword.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import back from "../../img/loginBack.svg";
import success from "../../img/successEmailSend.svg";

function ForgetPassword({setForgetPassword}) {
	const {store} = useContext(Context);
	const [email, setEmail] = useState("");
	const [emailSent, setEmailSent] = useState(false);

	const emailHandler = (e) =>{
		e.preventDefault();
		setEmail(e.target.value)
	}
	const handleResetPassword = async () => {
		try {
			await store.forgetPassword(email);
			if (!Object.keys(store.errors).length) {
				setEmailSent(true);
				setEmail("");
			}


		} catch (error) {
			console.error('Ошибка при входе:', error);
		}
	}


	return (
		<div className="__forget_password">
			{
				emailSent ?
					<>
						<img className="_back" onClick={() => setEmailSent(false)} src={back} alt="img"/>
						<h3>Письмо успешно отправлено на почту {email}</h3>
						<img className="_success" src={success} alt="img"/>
						<h5>Если письмо не пришло, повторите попытку проверив правильность почты</h5>
					</>
					:
					<>
						<img className="_back" onClick={() => setForgetPassword(false)} src={back} alt="img"/>
						<h2>Восстановление пароля</h2>
						<p>Укажите свой email, под которым вы зарегистрированы на сайте и на него будет отправлена информация о восстановлении пароля</p>
						<input
							onChange={(e) => emailHandler(e)}
							value={email}
							type="text"
							className={store.errors.email || store.errors.emailNotFound ? 'invalid-input' : ''}
							placeholder="Email"
							autoComplete="email"
						/>

						{store.errors.email && <div className="error-message">{store.errors.email}</div>}
						{store.errors.emailNotFound && <div className="error-message">{store.errors.emailNotFound}</div>}
						{store.errors.general && <div className="error-message">{store.errors.general}</div>}
						<button onClick={handleResetPassword}>Отправить письмо</button>
					</>
			}
		</div>
	);
}

export default observer(ForgetPassword);
import React, {useContext} from 'react';
import "./ProfileMenu.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function ProfileMenu({selectedItem, setSelectedItem, setSelectedButton}) {
	const {store} = useContext(Context);


	const handleButtonChange = (data) => {
		setSelectedButton(data);
		setSelectedItem(data);
	}

	return (
		<div className="__menu">
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'account' ? 'selected' : ''}`} onClick={() => handleButtonChange("account")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M16 8a4 4 0 11-8 0 4 4 0 018 0zm-8 6a4 4 0 00-4 4v2h16v-2a4 4 0 00-4-4H8z"></path>
						</svg>
					</div>
					<span>Аккаунт</span>
				</button>
			</div>
			<div className="__item">
				<button className= {`__list_item ${selectedItem === 'transactions' ? 'selected' : ''}`} onClick={() => handleButtonChange("transactions")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M4.5 3v18h4.91A7.5 7.5 0 0118.5 9.365V7l-4-4h-10zm16 13a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-4.79-2.875h-2v4l3.031 1.75 1-1.732-2.031-1.173v-2.845z"></path>
						</svg>
					</div>
					<span>Транзакции</span>
				</button>
			</div>
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'referral' ? 'selected' : ''}`} onClick={() => handleButtonChange("referral")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M11 8.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zM2 17a3 3 0 013-3h5a3 3 0 013 3v3H2v-3zm14.5-1v-3h-3v-3h3V7h3v3h3v3h-3v3h-3z"></path>
						</svg>
					</div>
					<span>Рефералная программа</span>
				</button>
			</div>
			<div className="__item">
				<button className={`__list_item ${selectedItem === 'settings' ? 'selected' : ''}`} onClick={() => handleButtonChange("settings")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M13.8 3h-3.6v2.027c-.66.17-1.285.431-1.858.77L6.91 4.363 4.363 6.91l1.434 1.433a7.157 7.157 0 00-.77 1.858H3v3.6h2.027c.17.66.431 1.285.77 1.858L4.363 17.09l2.546 2.546 1.433-1.434c.573.339 1.197.6 1.858.77V21h3.6v-2.027a7.157 7.157 0 001.858-.77l1.433 1.434 2.546-2.546-1.434-1.434a7.16 7.16 0 00.77-1.857H21v-3.6h-2.027a7.158 7.158 0 00-.77-1.858l1.434-1.433-2.546-2.546-1.434 1.434a7.156 7.156 0 00-1.857-.77V3zm-4.5 9a2.7 2.7 0 115.4 0 2.7 2.7 0 01-5.4 0z"></path>
						</svg>
					</div>
					<span>Настройки</span>
				</button>
			</div>


			<div className="__item">
				<button className={`__list_item ${selectedItem === 'support' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("support")}>
					<div className="__image">
						<svg className="__profile_svg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd"
							      d="M21.002 17v-5a9.113 9.113 0 00-.055-1 9.001 9.001 0 00-17.945 1v5h5v-6H5.578a6.502 6.502 0 0112.848 0h-2.424v6h.899a6.988 6.988 0 01-3.289 1.814 2 2 0 10.217 2A9.007 9.007 0 0019.486 17h1.516z"></path>
						</svg>
					</div>
					<span>Поддержка</span>
				</button>
			</div>
			{
				store.user.role === "ADMIN" &&
				<div className="__item">
					<button className={`__list_item ${selectedItem === 'admin' ? 'selected' : ''}`}
					        onClick={() => handleButtonChange("admin")}>
						<div className="__image">
							<svg className="__profile_svg svg_admin" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
								<g id="SVGRepo_iconCarrier">
									<path
										d="M1556.611 1920c-54.084 0-108.168-20.692-149.333-61.857L740.095 1190.96c-198.162 41.712-406.725-19.269-550.475-163.019C14.449 852.771-35.256 582.788 65.796 356.27l32.406-72.696 390.194 390.193c24.414 24.305 64.266 24.305 88.68 0l110.687-110.686c11.824-11.934 18.283-27.59 18.283-44.34 0-16.751-6.46-32.516-18.283-44.34L297.569 84.207 370.265 51.8C596.893-49.252 866.875.453 1041.937 175.515c155.026 155.136 212.833 385.157 151.851 594.815l650.651 650.651c39.961 39.852 61.967 92.95 61.967 149.443 0 56.383-22.006 109.482-61.967 149.334l-138.275 138.385c-41.275 41.165-95.36 61.857-149.553 61.857Z"
										fillRule="evenodd"></path>
								</g>
							</svg>
						</div>
						<span>Панель управления</span>
					</button>
				</div>
			}
		</div>
	);
}

export default observer(ProfileMenu);
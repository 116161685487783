import React from 'react';
import "./About.scss";
import {useNavigate} from "react-router-dom";

function About() {
	const navigate = useNavigate();
	const navigateToLogin = () => {
		navigate("/");
		window.scrollTo(0,0);
	}
	return (
		<div className="about">
			<div className="container-top">
				<div className="__title">
					<h2>Добро пожаловать в DEXPAY</h2>
					<p>Ваш путь к безопасной и легальной покупке виртуальных активов.
						Наша миссия - сделать мир виртуальных активов доступным и безопасным для всех. Мы стремимся предоставить нашим пользователям простой и прозрачный способ приобретения виртуальных активов, соблюдая все законодательные требования и нормативы.
						Присоединяйтесь к нам сегодня и откройте для себя мир цифровых валют с DEXPAY.</p>
					<button onClick={navigateToLogin}>Начать</button>
				</div>
				<div className="__who_container">
					<div className="__who">
						<div className="__text">
							<h2>Кто мы?</h2>
							<div className="__line"></div>
							<div className="__info">
								<div>Мы - компания ООО "ДИТ", обладающая государственной ИТ аккредитацией, и специализирующаяся на разработке передовых ИТ продуктов. Веря в потенциал технологий блокчейна и web 3, мы создали DEXPAY, сервис, который обеспечивает надежные и удобные возможности приобретения виртуальных активов.
									DEXPAY - это не просто сервис, это ваш надежный партнер в мире виртуальных активов. Мы ценим доверие наших клиентов и гордимся тем, что можем предложить им качественные услуги и поддержку на каждом этапе использования нашего сервиса.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
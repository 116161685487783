import React, {useState} from 'react';
import "./Partners.scss";
import countries from "../../countries.json";
import axios from "axios";

function Partners() {
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [position, setPosition] = useState("");
	const [country, setCountry] = useState("");
	const [city, setCity] = useState("");
	const [number, setNumber] = useState("");
	const [telegram, setTelegram] = useState("");
	const [linkedIn, setLinkedIn] = useState("");
	const [info, setInfo] = useState("");
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);

	const [isValidName, setIsValidName] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidNumber, setIsValidNumber] = useState(true);
	const [isValidCountry, setIsValidCountry] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const [searchCountry, setSearchCountry] = useState("");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const filteredCountries = countries.countries.filter((country) =>
		country.name.toLowerCase().startsWith(searchCountry.toLowerCase())
	);

	const handleFind = (e) => {
		setSearchCountry(e.target.value);
		setIsDropdownOpen(true);
	}

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true);
	};
	const handleSurnameChange = (event) => {
		setSurname(event.target.value);
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValidEmail(true);
	};
	const handleCompanyChange = (event) => {
		setCompanyName(event.target.value);
	};
	const handlePositionChange = (event) => {
		setPosition(event.target.value);
	};
	const handleCountryChange = (countryName) => {
		const selectedCountryCode = countries.countries.find(
			(country) => country.name.toLowerCase() === countryName.toLowerCase()
		);
		setCountry(countryName);
		setSearchCountry(countryName);
		setIsDropdownOpen(false);
		setNumber(selectedCountryCode.callingCode || "");
		setIsValidCountry(true);
	};
	const handleCityChange = (event) => {
		setCity(event.target.value);
	};
	const handleNumberChange = (event) => {
		let inputValue = event.target.value;
		inputValue = inputValue.replace(/[^0-9+()\-\s]/g, '');
		if (!inputValue.startsWith("+")) {
			inputValue = "+" + inputValue;
		}
		setNumber(inputValue);
		setIsValidNumber(true);
	};
	const handleLinkedInChange = (event) => {
		setLinkedIn(event.target.value);
	};
	const handleTelegramChange = (event) => {
		setTelegram(event.target.value);
	}
	const handleInfoChange = (event) => {
		setInfo(event.target.value);
	}

	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = async () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const isValidEmail = emailPattern.test(email);
		setIsValidEmail(isValidEmail);

		const isPhoneNumValid = number !== "" && number.length >= 8;
		setIsValidNumber(isPhoneNumValid);

		const isCountryValid = country !== "" && searchCountry !== "";
		setIsValidCountry(isCountryValid);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if(
			isValidName &&
			isValidEmail &&
			isValidNumber &&
			isValidCountry &&
			isPrivacyPolicyValid
		){
			const data = {
				service_id: "service_zfl7wh6",
				template_id: "template_hg7vjzw",
				user_id: "hlz_O_IK2KQlPFddA",
				template_params: {
					'name': name,
					'surname': surname,
					'email': email,
					'companyName': companyName,
					'position': position,
					'country': country,
					'city': city,
					'number': number,
					'telegram': telegram,
					'linkedIn': linkedIn,
					'info': info,
				}
			};

			axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
				.then(response => {
					console.log("Successful sent");
				})
				.catch(error => {
					console.error('Oops... ' + error);
				});
			setName("");
			setSurname("");
			setEmail("");
			setCountry("");
			setSearchCountry("");
			setNumber("");
			setCity("");
			setCompanyName("");
			setPosition("")
			setTelegram("");
			setLinkedIn("")
			setInfo("")
			setIsPrivacyPolicyAgreed(false);
		}
	}

	return (
		<div className="partners">
			<div className="container">
				<div className="form-wrapper">
					<div className="form">
						<h2>Стать партнером</h2>
						<div className="input-group">
							<div className="form-input">
								<label>Имя<span>*</span></label>
								<input type="text"
								       value={name}
								       onChange={handleNameChange}
								       className={!isValidName ? "invalid" : ""}
								       onClick={() => setIsDropdownOpen(false)}
								/>
								{!isValidName && (
									<p className="error-message">Заполните поле</p>
								)}
							</div>
							<div className="form-input">
								<label>Фамилия</label>
								<input type="text"
								       value={surname}
								       onChange={handleSurnameChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>E-mail<span>*</span></label>
								<input type="text"
								       value={email}
								       onChange={handleEmailChange}
								       className={!isValidEmail ? "invalid" : ""}
								       onClick={() => setIsDropdownOpen(false)}
								/>
								{!isValidEmail && (
									<p className="error-message">Заполните поле</p>
								)}
							</div>
							<div className="form-input">
								<label>Страна<span>*</span></label>
								<div className="custom-select-container">
									<div className="custom-select" >
										<input
											type="text"
											value={searchCountry}
											onChange={(e) => handleFind(e)}
											onClick={() => setIsDropdownOpen(!isDropdownOpen)}
											className={!isValidCountry ? "invalid" : ""}
										/>
										{!isValidCountry && (
											<p className="error-message">Заполните поле</p>
										)}
										{isDropdownOpen && (
											<ul className="dropdown">
												{filteredCountries.map((item, i) => (
													<li
														key={i}
														className="dropdown-item"
														onClick={() => handleCountryChange(item.name)}
													>
														{item.name}
													</li>
												))}
											</ul>
										)}
									</div>
								</div>
							</div>
							<div className="form-input">
								<label>Номер телефона<span>*</span></label>
								<input type="text"
								       value={number}
								       onChange={handleNumberChange}
								       className={!isValidNumber ? "invalid" : ""}
								       onClick={() => setIsDropdownOpen(false)}
								/>
								{!isValidNumber && (
									<p className="error-message">Заполните поле</p>
								)}
							</div>
							<div className="form-input">
								<label>Город</label>
								<input type="text"
								       value={city}
								       onChange={handleCityChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>Компания</label>
								<input type="text"
								       value={companyName}
								       onChange={handleCompanyChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>Должность</label>
								<input type="text"
								       value={position}
								       onChange={handlePositionChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>Telegram</label>
								<input type="text"
								       value={telegram}
								       onChange={handleTelegramChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>LinkedIn</label>
								<input type="text"
								       value={linkedIn}
								       onChange={handleLinkedInChange}
								       onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
							<div className="form-input">
								<label>Опишите свои потребности</label>
								<textarea value={info}
								          onChange={handleInfoChange}
								          onClick={() => setIsDropdownOpen(false)}
								/>
							</div>
						</div>
						<div className="partner-btn">
							<button onClick={handleSubmit}>Стать партнером</button>
							<div className="form-agree">
								<div className="agree-text">
									<input
										type="checkbox"
										id="privacyPolicyAgree"
										checked={isPrivacyPolicyAgreed}
										onChange={handlePrivacyPolicyChange}
									/>
									<label htmlFor="privacyPolicyAgree">
										Вы соглашаетесь с
										<a href="/privacy"> политикой конфиденцальности</a>
										<span>*</span>
									</label>
								</div>
								{!isPrivacyPolicyValid && (
									<p className="error-message">Примитие политику конфиденцальности</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Partners;